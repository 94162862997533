//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import the component
import Treeselect from '@riophae/vue-treeselect';
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  components: { Treeselect },
  props: {
    display: { type: String, require: true, default: () => 'list' },
    queryParam: { type: Object, require: true },
    category: { type: Object }
  },
  data() {
    return {
      categoryValue: [],
      statusList: [
        {
          key: 'all',
          value: 'All'
        },
        {
          key: 'PUBLISHED',
          value: 'Published'
        },
        {
          key: 'FAILED',
          value: 'Failed'
        },
        {
          key: 'DRAFT',
          value: 'Draft'
        }
      ],
      statusTimer: null
    };
  },
  computed: {
    acitveClearButton() {
      const { title, status, category } = this.queryParam;
      return title !== '' || status !== 'all' || category;
    },
    statusText() {
      const { status } = this.queryParam;
      const statusItem = this.statusList.find(item => item.key === status);
      return statusItem ? this.$t(statusItem.value) : this.$t('All');
    },
    categorySetting() {
      const setting = {
        showBadge: false,
        title: this.$t('code.grid.Category'),
        count: 0
      };

      if (this.categoryValue.length > 0) {
        const firstCategory = this.category.options.find(item => item.id === this.categoryValue[0]);
        setting.title = this.$t('code.grid.Category') + `: ${firstCategory?.name || ''}`;
        setting.showBadge = this.categoryValue.length > 1;
        setting.count = this.categoryValue.length;
      }
      return setting;
    }
  },
  created() {
    this.categoryValue = this.queryParam.category ? JSON.parse(this.queryParam.category) : [];
  },

  methods: {
    toggleView() {
      const mode = this.display === 'list' ? 'grid' : 'list';
      this.$emit('toggleView', mode);
    },
    changeTitle(value) {
      this.$emit('changeTitle', value);
      this.handleSearch();
    },
    changeStatus(event) {
      this.$emit('changeStatus', event.key);
    },
    changeCategory() {
      // timeout to wait for the value to be updated, 1.5s
      clearTimeout(this.statusTimer);
      this.statusTimer = setTimeout(() => {
        this.$emit('changeCategory', this.categoryValue);
      }, 1500);
    },
    handleReset() {
      this.$emit('handleReset');
    },
    handleSearch() {
      this.$emit('handleSearch');
    }
  }
};
